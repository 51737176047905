<template>
  <Defaultlayout :userprofile="userprofile">
    <section class="best-seller">

      <div class="search-bar-filter pt-5">
        <div class="container">
          <div class="search-bar">
            <form @submit.prevent="postSearchnew">
              <div class="form-row">
                <div class="form-group mb-0">
                  <label for="staticEmail2" class="sr-only">search</label>
                  <input
                    type="text"
                    class="form-control"
                    id="staticEmail2"
                    :placeholder="(is_business_model == 3) ? 'Search Bazaar' : 'Search for item / Category / Store'"
                    v-model="searchqry"
                    @input="postSearchnew"
                  >
                </div>
                <button type="submit" :style="colorObj" class="btn btn-primary hoverEffect" @click="postSearchnew">
                  <span class="d-md-none">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15px" height="15px" viewBox="123.295 0 25.001 25" enable-background="new 123.295 0 25.001 25" xml:space="preserve">
                      <g>
                        <g>
                          <g>
                            <path fill="currentColor" d="M134.303,0c-6.069,0-11.008,4.938-11.008,11.01c0,6.07,4.938,11.01,11.008,11.01
                              c6.072,0,11.012-4.938,11.012-11.01C145.314,4.938,140.375,0,134.303,0z M134.303,19.986c-4.948,0-8.977-4.027-8.977-8.978
                              s4.028-8.978,8.977-8.978c4.951,0,8.979,4.027,8.979,8.978C143.279,15.959,139.254,19.986,134.303,19.986z"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path fill="currentColor" d="M147.997,23.266l-5.827-5.828c-0.396-0.396-1.041-0.396-1.436,0c-0.398,0.398-0.398,1.041,0,1.438
                              l5.824,5.826C146.76,24.9,147.018,25,147.279,25c0.259,0,0.52-0.1,0.718-0.298C148.395,24.307,148.395,23.662,147.997,23.266z"/>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>Search</button>
                <div class="filter d-flex flex-wrap">
                    <!-- <span class="filter-btn" v-b-toggle.my-collapse>
                      <img src="../../assets/images/svgs/filter.svg" alt="filter" class="filter-img" />
                    </span> -->
                    <b-collapse id="my-collapse" class="category-tab">
                      <ul
                        class="nav nav-pills mb-3"
                        role="tablist"
                        v-if="advncefiltrlist.status=='1'"
                        id="pills-tab"
                      >
                        <li
                          role="presentation"
                          v-for="(fltrlist,index) in advncefiltrlist.data"
                          v-bind:key="index"
                          class="nav-item"
                        >
                          <a
                            :class="(index==0) ? 'nav-link active':'nav-link'"
                            id="pills-store-tab"
                            data-toggle="pill"
                            :href="'#'+fltrlist.key"
                            role="tab"
                            aria-controls="pills-store"
                            aria-selected="true"
                          >{{fltrlist.name}}</a>
                        </li>
                      </ul>
                      <div class="tab-content" id="pills-tabContent">
                        <div
                          v-for="(nwfltrlist,index) in advncefiltrlist.data"
                          v-bind:key="index"
                          :class="(index==0) ? 'tab-pane fade show active':'tab-pane fade show'"
                          :id="nwfltrlist.key"
                          role="tabpanel"
                          :aria-labelledby="nwfltrlist.key"
                        >
                          <ul
                            class="categary-detail list-unstyled"
                            v-if="nwfltrlist.key=='store_type' || nwfltrlist.key=='online_order' || nwfltrlist.key=='delivery_type'"
                          >
                            <li
                              v-for="(newvl,index) in nwfltrlist.value"
                              v-bind:key="index"
                              @click="getActmenu(nwfltrlist.key,newvl.id,nwfltrlist.id);"
                              :id="'new'+nwfltrlist.id+newvl.id"
                              style="cursor:pointer;"
                              :class="(index == 0) ? 'active': ''"
                            >
                              <a class="d-flex">
                                {{newvl.name}}
                                <span class="right-skyblue">
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="13.135px" height="10px" viewBox="0 0 13.135 10" enable-background="new 0 0 13.135 10" xml:space="preserve">
                                    <polygon fill="#3AC6F4" points="4.179,7.91 1.044,4.776 0,5.821 4.179,10 13.135,1.044 12.089,0 "/>
                                  </svg>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-collapse>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>      

      <div class="container pt-5" v-if="loading==false">
        <h2 class="section-heading" v-if="configtitle!=undefined && configtitle!=''">{{configtitle}}</h2>
        <h2 class="section-heading" v-else>All Best Sellers</h2>
        <div class="row seller-row">
          <div
            class="col-md-6 col-lg-4 col-xl-3"
            v-for="(best_seller, index) in storebestsellerlist"
            v-bind:key="index"
          >
            <div class="product-main">
              <div class="product-type-category" v-if="best_seller.item_type == '0'"></div>
              <div class="product-type-category" v-if="best_seller.item_type == '1'">
                <img src="../../assets/images/svgs/veg.svg" height="20" width="20">
              </div>
              <div class="product-type-category" v-if="best_seller.item_type == '2'">
                <img src="../../assets/images/svgs/nonveg.svg" height="20" width="20">
              </div>
              <div class="product-type-category" v-if="best_seller.item_type == '3'">
                <img src="../../assets/images/svgs/egg.svg" height="20" width="20">
              </div>
              
              <div class="product-rating" v-if="(review_rating_module == 1) ? (best_seller.avg_rating != '' && best_seller.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                  <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                </svg> <span class="ml-1">{{best_seller.avg_rating}}</span>
              </div>

              <div class="product-rating" v-if="(review_rating_module == 1 && best_seller.avg_rating <=show_rating_different_color_code) ? (best_seller.avg_rating != '' && best_seller.avg_rating != null && show_review_rating == 1) : false" :style="{ backgroundColor:alternet_bg_color , color: alternate_color_code_text}">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                  <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="alternate_color_code_text"/>
                </svg> <span class="ml-1">{{best_seller.avg_rating}}</span>
              </div>

              <div class="product-rating" v-if="(review_rating_module == 1 && show_rating == 1) ? (best_seller.avg_rating == null && show_review_rating == 1) : false" :style="{ backgroundColor:default_rating_bg_color , color: default_rating_color_code_text}">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 19.23 18.29">
                  <path id="Path_86" data-name="Path 86" d="M113,1843.854l2.971,6.021,6.645.965-4.808,4.687,1.136,6.617L113,1859.021l-5.943,3.124,1.135-6.617-4.807-4.687,6.643-.965Z" transform="translate(-103.385 -1843.854)" :fill="default_rating_color_code_text"/>
                </svg> <span class="ml-1">5.0</span>
              </div>

              <div class="top-box">

                <div class="product-image">
                  <router-link :to="'/productdetail?itemid=' + best_seller.item_id" class="d-block">
                    <v-lazy-image :src="best_seller.item_image" src-placeholder="best_seller.item_image" alt="product" width="150" height="150" />
                  </router-link>
                </div>

                <div class="product-wrap-box">
                  <div class="middle-box">

                    <div v-if="best_seller.mask_price == 1" class="price row mx-0 justify-content-between align-items-center">
                      <h5 class="contact-store">Contact store for price</h5>
                    </div>

                    <div v-else class="price row mx-0 justify-content-between align-items-center">
                      <p class="main-price">
                        <span v-html="priceFormatter(getItemDiscountedPrice(best_seller, index))"></span>
                      </p>
                      <p class="delet-price" v-if="getItemPrice(best_seller, index) != '0'">
                        <span>
                          <!-- MRP -->
                          <span v-html="priceFormatter(getItemPrice(best_seller, index))"></span>
                        </span>
                      </p>
                    </div>

                    <h5 class="brand-name" v-if="best_seller.brand_name != ''">
                      {{ best_seller.brand_name }}
                    </h5>

                    <router-link
                      :to="'/productdetail?itemid=' + best_seller.item_id"
                      class="product-label"
                      :title="best_seller.item_name"
                    >{{ best_seller.item_name | strippedItemname }}
                    </router-link>
                    <div class="text-left">
                          <span v-if="best_seller.item_variants[0].min_qty > 0 && best_seller.item_variants[0].max_qty > 0">
                            (Min {{ best_seller.item_variants[0].min_qty }} - Max {{ best_seller.item_variants[0].max_qty }})
                          </span>
                          <span v-if="best_seller.item_variants[0].min_qty > 0 && best_seller.item_variants[0].max_qty == 0">
                            (Min {{ best_seller.item_variants[0].min_qty }})
                          </span>
                          <span v-if="best_seller.item_variants[0].min_qty == 0 && best_seller.item_variants[0].max_qty > 0">
                            (Max {{ best_seller.item_variants[0].max_qty }})
                          </span>
                        </div>
                    <h5
                      :style="textcolor"
                      v-if="getChoiceAddon(best_seller, 0) != null && getChoiceAddon(best_seller, 0) == '0'"
                      class="available-label"
                    >Choices/Addons Available</h5>
                    
                    <h5
                      :style="textcolor"
                      v-if="getChoiceAddon(best_seller, 1) != null && getChoiceAddon(best_seller, 1) == '1'"
                      class="available-label"
                    >Choices Available</h5>
                    
                    <h5
                      :style="textcolor"
                      v-if="getChoiceAddon(best_seller, 2) != null && getChoiceAddon(best_seller, 2) == '2'"
                      class="available-label"
                    >Addons Available</h5>

                    <h5 v-if="best_seller.prescription == 1" class="prescription-required" style="color:#FF3333">
                      Prescription required
                    </h5>

                    <h5 v-if="best_seller.item_variants.length == 1">
                      <span v-if="parseFloat(best_seller.item_variants[0].unit_quantity) != '0'">
                        {{ Number( best_seller.item_variants[0].unit_quantity ).toFixed(1) }}
                      </span>
                      <span v-if="best_seller.item_variants[0].unit_name != null">
                        {{ best_seller.item_variants[0].unit_name }}
                      </span>
                      <span v-if="best_seller.item_variants[0].packaging_name != null">
                        {{ best_seller.item_variants[0].packaging_name }}
                      </span>
                      <input type="hidden" v-model="item_variants[index].item_variants_id">
                    </h5>
                    <select 
                      v-else
                      class="form-control custmzdropdwn"
                      v-model="item_variants[index].item_variants_id"
                    >
                      <option
                        v-for="(variant,index) in best_seller.item_variants"
                        v-bind:value="variant.item_variants_id"
                        v-bind:key="index"
                      >
                        <span v-if="parseFloat(variant.unit_quantity) != '0'">
                          {{ Number( variant.unit_quantity ).toFixed(1) }}
                        </span>
                        <span v-if="variant.unit_name != null">
                          {{ variant.unit_name }}
                        </span>
                        <span v-if="variant.packaging_name != null">
                          {{ variant.packaging_name }}
                        </span>
                        <!-- <span v-if="variant.min_qty > 0">
                          (Min {{ variant.min_qty }} - Max {{ variant.max_qty }})
                        </span> -->
                        
                      </option>
                    </select>
                  <h5 class="product-short-info" :title="best_seller.item_description" >{{ best_seller.item_description | descriptionOfGridViewItem }}</h5>                    
                  </div>
                </div>
              </div>
              <div class="bottom-box" v-if="best_seller.out_of_stock==0">
                  <div class="bottom-row row mx-0 quentity-selection" :id="'newaddcartitmvrtn' + best_seller.item_id" :style="isItemAdded(best_seller, index) === true ? 'display:inline;' : 'display:none;'">
                    <div class="quentity-selector">
                      <div class="number row mx-0">
                        <span class="minus" @click="removeQuantity(best_seller, index)">-</span>
                        <input
                          type="text"
                          v-bind:value="getItemQuantity(best_seller, index)"
                          :name="'quantityid' + best_seller.item_id"
                          :id="'quantityid' + best_seller.item_id"
                          @keypress="onlyNumber"
                        >
                        <span class="plus" @click="addQuantity(best_seller, index)">+</span>
                      </div>
                    </div>
                    <input
                      type="hidden"
                      name="itemvarintid"
                      :id="'itemvarintid' + best_seller.item_id"
                      :value="getItemVarintId(best_seller, index)"
                    >
                  </div>
                  <div class="bottom-row row mx-0">
                    <div
                      class="button"
                      :id="'addcartitmvrtn' + best_seller.item_id"
                      :style="isItemAdded(best_seller, index) === false ? 'display:inline;' : 'display:none;'"
                    >
                      <input
                        type="hidden"
                        name="itemvarintid"
                        :id="'itemvarintid' + best_seller.item_id"
                        :value="getItemVarintId(best_seller, index)"
                      >
                      <a
                        style="cursor:pointer;"
                        :style="colorObj"
                        class="btn btn-primary hoverEffect"
                        @click="addToCart(best_seller, index)"
                      >Add</a>
                    </div>
                  </div>
              </div>

              <div class="bottom-box" v-if="best_seller.out_of_stock==1">
                <div class="bottom-row row mx-0">
                  <div class="button">
                    <a
                      :class="(best_seller.notify_me==1) ? 'btn btn-primary notifymegrayitem' : 'btn btn-primary'"
                      style="cursor: pointer;"
                      :style="colorObj"
                      :id="'btnnotifyme' + best_seller.item_id"
                      @click="productNotify(best_seller.item_id)"
                    >Notify Me</a>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              :id="'ReplaceCartModal'+index"
              tabindex="-1"
              role="dialog"
              aria-labelledby="ReplaceCartModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="ReplaceCartModalLabel">Replace cart item(s)?</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">{{ cart_message }}</div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="removeCart(best_seller, index)">Yes</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              :id="'QuantityAlertModal'+index"
              tabindex="-1"
              role="dialog"
              aria-labelledby="QuantityAlertModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="QuantityAlertModalLabel">Quantity Alert</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">{{ qty_alert_message }}</div>
                  <div class="modal-footer">
                    <button v-if="is_min_qty" type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button v-if="is_min_qty" type="button" :style="colorObj" class="btn btn-primary hoverEffect" @click="(remove_min_qty == true) ? removeMinQty(best_seller, index) : addMinQty(best_seller, index)">Yes</button>
                    <button v-else type="button" class="btn btn-primary" data-dismiss="modal">OK</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade repeat-alert-modal"
              :id="'RepeatAlertModal'+index"
              tabindex="-1"
              role="dialog"
              aria-labelledby="RepeatAlertModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="RepeatAlertModalLabel" v-if="repeat_addon_choice != null">
                        {{repeat_addon_choice.item_name}}
                        <p>Repeat last used choices/addons?</p>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" v-if="repeat_addon_choice != null">
                    <h6>
                      <span v-for="(choice_addon, ind) in repeat_addon_choice.choice_addon_details" v-bind:key="ind">
                        <span>{{choice_addon}}</span><br>
                      </span>
                    </h6>
                    <h5><span v-html="priceFormatter(repeat_addon_choice.discounted_price)"></span></h5>
                  </div>
                  <div class="modal-footer">
                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemAddonChoices(best_seller, index)">Repeat</button>
                    <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemAddonChoices(best_seller, index)">Add New</button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade repeat-alert-modal"
              :id="'RepeatComboAlertModal'+index"
              tabindex="-1"
              role="dialog"
              aria-labelledby="RepeatComboAlertModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="RepeatComboAlertModalLabel" v-if="repeat_combo != null">
                        {{repeat_combo.item_name}}
                        <p>Repeat the combo choices?</p>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" v-if="repeat_combo != null">
                    <h6>
                      <span v-for="(combo, ind) in repeat_combo.combo_variants" v-bind:key="ind">
                        <span>{{combo.combo_variant_name}}</span><br>
                        <span class="ml-4" v-if="parseFloat(combo.unit_quantity) != '0' || combo.unit_name != null || combo.packaging_name != null">
                            <span v-if="parseFloat(combo.unit_quantity) != '0'">
                              {{ Number( combo.unit_quantity ).toFixed(1) }}
                            </span>
                            <span v-if="combo.unit_name != null">
                              {{ combo.unit_name }}
                            </span>
                            <span v-if="combo.packaging_name != null">
                              {{ combo.packaging_name }}
                            </span>
                            <br>
                        </span>
                        <h6 class="w-100 mb-2 text-black" v-if="combo.choices != null">
                          <span v-for="(choice,ind) in filterComboChoices(combo.choices)" v-bind:key="ind">
                            <span v-if="(ind == 0)">{{choice.variant_choice_name}}: </span>
                            {{choice.variant_sub_choice_name}}<template v-if="ind + 1 < filterComboChoices(combo.choices).length">, </template> 
                          </span>
                        </h6>
                        <h6 class="w-100 mb-2 text-black" v-if="combo.addons != null">
                          <span v-for="(addon,ind) in filterComboAddons(combo.addons)" v-bind:key="ind">
                            <span v-if="(ind == 0)">{{addon.variant_addon_name}}: </span>
                            {{addon.variant_sub_addon_name}}<template v-if="ind + 1 < filterComboAddons(combo.addons).length">, </template> 
                          </span>
                        </h6>
                      </span>
                    </h6>
                    <br>
                    <h5><span v-html="priceFormatter(repeat_combo.discounted_price)"></span></h5>
                  </div>
                  <div class="modal-footer">
                    <button type="button" :style="colorObj" class="btn repeat-btn hoverEffect" @click="repeatItemCombo(best_seller, index)">Repeat</button>
                    <button type="button" :style="colorObj" class="btn add-new-btn hoverEffect" @click="addNewItemCombo(best_seller, index)">Add New</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <br/>
      
      <div class="container" v-if="loading == false && collection_status == 0">
        <div class="empty-box text-center">
          <div class="contents">
            <div class="empty-image search-not-found">
              <img src="../../assets/images/svgs/search-not-found.svg" alt="search-not-found">
            </div>
          <h3>{{ collection_message }}</h3>
          </div>
          <router-link :to="{ name: 'home'}" :style="colorObj" class="btn btn-primary hoverEffect btn-large">Go to Home</router-link>
        </div>
      </div>

      <div class="paggination" v-if="storebestsellerlist!=null && loading!=true">
        <p class="text-center" v-if="storebestsellerlist.length > 0">
          <span v-if="page_number >= 1 && record_count > 20">
            <button
            v-show="pageCount > 1"
            :disabled="page_number === 1"
            @click="prevPage"
            :style="colorObj"
            class="btn btn-secondary hoverEffect"
            >Previous</button>
            &nbsp;
            <button
            v-show="pageCount > 1"
            :disabled="page_number >= pageCount || storebestsellerlist.length < 20"
            @click="nextPage"
            :style="colorObj"
            class="btn button-style hoverEffect"
            >Next</button>
          </span>
        </p>
      </div>

      <div class="container" v-if="loading==true">
          <p class="text-center">
            <img src="../../assets/images/loading.gif" />
          </p>
      </div>

    <CustomizeAlert pid="allbestseller"></CustomizeAlert>
    <ComboOption pid="allbestseller" @update="addToCartCombo" @closecombo="closeAddNewCombo"></ComboOption>
    <AvailableOption pid="allbestseller" @update="addToCartAddonChoices"></AvailableOption>
    </section>
  </Defaultlayout>
</template>
<script>
import Defaultlayout from "../../layouts/Defaultlayout";
import { bus } from "../../main";
import AvailableOption from "../product/AvailableOption";
import ComboOption from "../product/ComboOption";
import CustomizeAlert from "../product/CustomizeAlert";
import helper from "../../store/helper";
import VLazyImage from "v-lazy-image/v2";
export default {
  name: "ItemCollections",
  components: {
    Defaultlayout,
    VLazyImage,
    AvailableOption,
    ComboOption,
    CustomizeAlert
  },
  mixins:[helper],
  data() {
    return {
      loading: true,
      currency_symbol: "₹",
      userprofile: null,
      cart_data: null,
      cart_message: "",
      searchqry: "",
      advncefiltrlist: [],
      storetypearr: [0],
      deliveryarr: [0],
      onlineordrarr: [0],
      storebestsellerlist: null,
      best_seller_body: {
        user_id: '',
        lat: '',
        long: '',
      },
      get_cart_detail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      remove_cart: {
        user_id: "",
        cart_type: ""
      },
      add_to_cart: {
        user_id: "",
        store_id: "",
        item_id: "",
        item_variants_id: "",
        quantity: "",
        cart_type: "",
        choices: "",
        addons: "",
        combo_items: "",
        add_new: 0,
      },
      product_notify: {
        user_id: "",
        item_id: ""
      },
      local_cart: [],
      cart_response: null,
      item_variants: [],
      qty_alert_message: '',
      is_min_qty: false,
      remove_min_qty: false,
      addon_choice_cart: false,
      used_addon_choice: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_choices_addons: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_addon_choice: null,
      combo_cart: false,
      used_combo: {
        item_id: '',
        item_name: '',
        brand_name: '',
        item_variants_id: '',
        item_price: '',
        choice_details: [],
        addon_details: [],
      },
      used_combo_data: {
        user_id: '',
        store_id: '',
        item_variants_id: '',
      },
      repeat_combo: null,
      item_qty: 0,
      combo_item_data: {
         item_id: "",
         user_id: "",
       },
      combo_item_details: null,
      selectedComboItems: [],
      show_review_rating : 1,
      review_rating_module : 1,
      default_rating_bg_color:"",
      default_rating_color_code_text:"",
      show_rating_different_color_code: 0,
      alternet_bg_color:"",
      alternate_color_code_text:"",
      show_rating: 0,
      show_price_same_font:1,
      show_price_inclusive_tax:1,
      show_price_inclusive_packaging:1,
      configtitle:"",
      record_count: 0,
      size: 20,
      page_number: 1,
      items_body:{
        user_id:"",
        store_id:"",
        page:1,
        item_collection_id:"",
        limit:"",
      },
      is_business_model:'',
      collection_status:0,
      collection_message:""
    };
  },
  mounted() {
    this.is_business_model = localStorage.getItem("is_business_model");
    this.configtitle = this.$route.query.type;
    if (localStorage.getItem("user") != null) {
      this.userprofile = JSON.parse(localStorage.getItem("user"));
    } else {
      this.userprofile = null;
    }
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹"; 
    this.getBestSellers();
    this.getAdvnceFltrList();
    window.$('<style>.category-tab .nav-pills .nav-link.active { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .nav-pills .nav-link.active { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    window.$('<style>.category-tab .categary-detail li.active a { color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body');
    this.originalColor();
    bus.$on("globalsetting",data => {
      if(Object.keys(data).length != 0){
        this.review_rating_module = parseFloat(JSON.parse(data).review_rating_module);
        this.show_review_rating = parseFloat(JSON.parse(data).show_review_rating);
        this.default_rating_bg_color = JSON.parse(data).default_rating_color_code_bg;
        this.default_rating_color_code_text = JSON.parse(data).default_rating_color_code_text;
        this.show_rating_different_color_code = JSON.parse(data).show_rating_different_color_code;
        this.alternet_bg_color = JSON.parse(data).alternate_color_code_bg;
        this.alternate_color_code_text = JSON.parse(data).alternate_color_code_text;
        this.show_rating = JSON.parse(data).show_rating;
        this.show_price_same_font = JSON.parse(data).show_price_same_font;
        this.show_price_inclusive_tax = JSON.parse(data).show_price_inclusive_tax;
        this.show_price_inclusive_packaging = JSON.parse(data).show_price_inclusive_packaging;
      }
    })
  },
  updated(){
    window.$('.right-skyblue svg polygon').css({ fill: localStorage.getItem("button_default_color")});
  },
  filters: {
    strippedItemname: function(value) {
      if (value.length > 27) {
        return value.slice(0, 24) + "...";
      }
      return value;
    },
    descriptionOfGridViewItem: function(value){
      if(value.length >= 60){
        return value.slice(0,60) + "...";
      }
      return value;
    }
  },
  computed: {
    totalCartQuantity() {
      var total_qty = 0;
      if(this.cart_data != null){
        if(this.cart_data.status != 0){
          total_qty = this.cart_data.data.cart_details.quantity;
        }
      }
      return total_qty;
    },
    pageCount() {
        if (this.storebestsellerlist.length > 0) {
          let l = this.record_count,
          s = this.size;
          return Math.ceil(l / s);
        } else {
          return "0";
        }
      }
  },
  methods: {
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        $event.preventDefault();
      }
    },
    prevPage() {  
        this.page_number--;
        this.getBestSellers();
        if(window.$('.search-bar-filter').length > 0){
          var sticky_banner = window.$('.search-bar-filter').height();
          window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
        }
    },
    nextPage() {
      this.page_number++;
      this.getBestSellers();
      if(window.$('.search-bar-filter').length > 0){
        var sticky_banner = window.$('.search-bar-filter').height();
        window.$("html, body").animate({ scrollTop: (sticky_banner - 220) }, "slow");
      }
    },
    async getBestSellers() {
      this.storebestsellerlist = null;
      this.items_body.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.items_body.store_id = localStorage.getItem("store_id");
      this.items_body.item_collection_id = this.$route.query.id;
      this.items_body.search_query = this.searchqry;
      this.items_body.limit = 20;
      this.items_body.page= this.page_number;
      this.loading = true;
      await this.$store.dispatch("getshopsearchitemdetail", this.items_body).then(response => {
        this.collection_status = response.status;
        if(response.status == 0){
          this.loading = false;
          this.collection_message = response.message;
        } else {  
          var result = JSON.parse(JSON.stringify(response.data));
          this.storebestsellerlist = result;
          this.record_count = response.count;
          if(localStorage.getItem("user") == null && localStorage.getItem("user_cart") != null) {
              var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

              bus.$emit("getcartlength", user_cart.length);

              if(this.storebestsellerlist != null && user_cart.length > 0) {
                user_cart.forEach(e => {

                  let item_index = this.storebestsellerlist.findIndex(
                      o => o.item_id == e.item_id
                  );

                  if (item_index > -1){
                    let item_variant_index = this.storebestsellerlist[item_index].item_variants.findIndex(
                        o => o.item_variants_id == e.item_variants_id
                    );

                    if (item_variant_index > -1){
                      this.storebestsellerlist[item_index].item_variants[item_variant_index].is_added = 1;
                      this.storebestsellerlist[item_index].item_variants[item_variant_index].quantity = e.quantity;
                    }
                  }

                });
              }
            }

            this.item_variants = [];
            if(this.storebestsellerlist != null){
              this.storebestsellerlist.forEach(e => {
                var obj = {};
                obj['item_variants_id'] = e.item_variants[0].item_variants_id;
                obj['quantity'] = (e.item_variants[0].quantity != null) ? e.item_variants[0].quantity : 1;
                obj['is_added'] = e.item_variants[0].is_added;
                this.item_variants.push(obj);
              });
            }
            this.loading = false;  
          }
      });
    },
    postSearchnew() {
      //localStorage.setItem("searchqry", this.searchqry);
      this.getBestSellers();
    },
    getAdvnceFltrList() {
      this.$store.dispatch("getadvancefiltrlist").then(response => {
        this.advncefiltrlist = JSON.parse(JSON.stringify(response));
        if(this.advncefiltrlist.status == 1){
          if(localStorage.getItem("user") == null){
            var advncefiltrlist =  this.advncefiltrlist.data.filter(function(item) {
              if (item.key != "store_type") return item;
            });
            this.advncefiltrlist.data = advncefiltrlist;
          }
        }
      });
    },
    priceFormatter(value) {
      if(value){
        var price = value.toString().split(".")[0];
        var decimal = value.toString().split(".")[1];
        var response = this.currency_symbol + price;
        if(decimal != undefined){
          response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
        } else {
          response += "<span class='price-decimal'><small>.00</small></span>";
        }
        return response;
      } else {
        return this.currency_symbol + '0';
      }
    },
    isItemAdded(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).is_added == 1 
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getItemQuantity(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
        if(item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ).quantity != null){
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).quantity;
        } else {
          return 1;  
        }
      } else {
        return 1;
      }
    },
    getItemVarintId(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
        bus.$emit(
          "itemvariantid",
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).item_variants_id
        );
        return item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ).item_variants_id;
      } else {
        return false;
      }
    },
    getItemPrice: function(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          var item_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).item_price;
          var tax_item_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).tax_item_price;
          var total_sum = 0;
        if (
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).discounted_price ==
          item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).item_price
        ) {
          return '0';
        } else {
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return item_price;
          }
        }
      }
    },
    getItemDiscountedPrice: function(item, index) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          var discounted_item_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).discounted_price;
          var item_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).item_price;
          var tax_item_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).tax_item_price;
          var tax_item_discounted_price = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).tax_discounted_price;
          var total_sum = 0;
        if (
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).discounted_price) <
          parseFloat(item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).item_price)
        ) {
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){            
            total_sum = parseFloat(tax_item_discounted_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_discounted_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_discounted_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return discounted_item_price;
          }
        } else {
          if(this.show_price_inclusive_tax == 1 && this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_packaging == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else if(this.show_price_inclusive_tax == 1){
            total_sum = parseFloat(tax_item_price);
            return parseFloat(total_sum).toFixed(2);
          } else {
            return item_price;
          }
        }
      }
    },
    addQuantity(item, index) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
          if(item.item_variants[0].max_qty <= qty && qty != 0 && item.item_variants[0].max_qty != 0){
        this.$toast.error("The maximum quantity for item is " + item.item_variants[0].max_qty);
        return false;
      }
      qty = qty + 1;
      this.item_qty = parseInt(qty);
      this.addToCart(item, index);
      return false;
    },
    removeQuantity(item, index) {
      var qty = parseInt(window.$("#quantityid" + item.item_id).val());
      if(item.item_variants[0].min_qty > qty && qty != 0 & item.item_variants[0].min_qty != 0){
        this.$toast.error("The minimum quantity for item is " + item.item_variants[0].min_qty);
        return false;
      }
      if(item.item_variants[0] != undefined)
      {
        if (item.item_variants[0].min_qty >= qty)
          qty=0;
        else
          qty = qty -1;        
      }
      else{
        qty = qty -1;
      }
      if(qty != 0 && (this.checkItemAddonChoice(item,index) != null) || item.is_combo == 1){
        window.$('#CustomizeAlertModalallbestseller').modal("show");
        return false;
      } 
      window.$("#quantityid" + item.item_id).val(parseInt(qty));
      this.item_qty = parseInt(qty);
      this.addToCart(item, index);
      return false;
    },
    isStoreExistsInLocalCart(user_cart, cart_details){
      if(user_cart.length > 0){
        const i = user_cart.findIndex(o => o.store_id === cart_details.store_id);
        if (i > -1) return true;
        else return false;
      } else {
        return true;
      }
    },
    isItemExistsInLocalCart(user_cart, cart_details){
      const i = user_cart.findIndex(o => o.item_id === cart_details.item_id && o.item_variants_id === cart_details.item_variants_id);
      if (i > -1) {
        user_cart[i] = cart_details;
        if(cart_details.quantity == 0){
          user_cart.splice(i, 1);
        }
      } else { 
        user_cart.push(cart_details);
      }
      return user_cart;
    },
    addToLocalCart(item, index){

      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = item.store_id;
      this.add_to_cart.item_id = item.item_id;
      this.add_to_cart.item_variants_id = window.$("#itemvarintid" + item.item_id).val();
      this.add_to_cart.quantity = window.$("#quantityid" + item.item_id).val();
      this.add_to_cart.cart_type = 1;

      var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];
      
      if(localStorage.getItem("user_cart") != null) {

        if(!this.isStoreExistsInLocalCart(user_cart, this.add_to_cart)) {
            this.cart_message = "Your cart contains items from different shop. Do you want to discard the selection and add items from current store?";
            window.$('#ReplaceCartModal'+index).modal("show");
            return false;
        }
        
        var new_user_cart = this.isItemExistsInLocalCart(user_cart, this.add_to_cart);
        localStorage.setItem('user_cart', JSON.stringify(new_user_cart));
        this.$toast.success("Cart details updated successfully");
      } else {
        user_cart.push(this.add_to_cart);
        localStorage.setItem('user_cart', JSON.stringify(user_cart));
        this.$toast.success("Cart details updated successfully");
      }

      if (
        this.storebestsellerlist[index].item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
        let item_variant_index = item.item_variants.findIndex(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
        );
        if(this.add_to_cart.quantity != 0){
          this.storebestsellerlist[index].item_variants[item_variant_index].is_added = 1;
          this.storebestsellerlist[index].item_variants[item_variant_index].quantity = this.add_to_cart.quantity;
        } else {
          this.storebestsellerlist[index].item_variants[item_variant_index].is_added = 0;
          this.storebestsellerlist[index].item_variants[item_variant_index].quantity = 1;
        }
      }
      bus.$emit("getcartlength", this.totalLocalCartQuantity(user_cart));
    },
    removeLocalCart() {
      var user_cart = JSON.parse(localStorage.getItem("user_cart")) || [];

      if(this.storebestsellerlist.length > 0 && user_cart.length > 0) {

        user_cart.forEach(e => {

          let item_index = this.storebestsellerlist.findIndex(
              o => o.item_id == e.item_id
          );

          if (item_index > -1){
            let item_variant_index = this.storebestsellerlist[item_index].item_variants.findIndex(
                o => o.item_variants_id == e.item_variants_id
            );

            if (item_variant_index > -1){
              this.storebestsellerlist[item_index].item_variants[item_variant_index].is_added = 0;
              this.storebestsellerlist[item_index].item_variants[item_variant_index].quantity = 1;
            }
          }

        });
      }

      this.local_cart = [];
      localStorage.removeItem("user_cart");
      bus.$emit("getcartlength", this.local_cart.length);
    },
    totalLocalCartQuantity(user_cart) {
      var total_qty = 0;
      user_cart.forEach(e => {
        total_qty+= parseFloat(e.quantity);
      });
      return Number(total_qty).toString();
    },
    getCartCount() {
      this.get_cart_detail.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.get_cart_detail.cart_type = 1;
      this.get_cart_detail.timezone = "+6";
      this.$store
        .dispatch("getcartdetail", this.get_cart_detail)
        .then(response => {
          this.cart_data = JSON.parse(JSON.stringify(response));
          bus.$emit("getcartlength", this.totalCartQuantity);
        });
    },
    getItemMinQty(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).min_qty;
      } else {
        return null;
      }
    },
    getItemMaxQty(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).max_qty;
      } else {
        return null;
      }
    },
    addMinQty(item, index) {
      var min_quantity = this.getItemMinQty(item,index);
      window.$("#quantityid" + item.item_id).val(parseInt(min_quantity));
      this.addToCart(item, index);
      window.$('#QuantityAlertModal'+index).modal("hide");
    },
    removeMinQty(item, index) {
      window.$("#quantityid" + item.item_id).val(parseInt(0));
      this.addToCart(item, index);
      window.$('#QuantityAlertModal'+index).modal("hide");
    },
    async addToCart(item, index) {

      if(localStorage.getItem("user") == null){
        this.openLoginfrm(item.item_id);
        return false;
      }
      
      this.add_to_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.add_to_cart.store_id = (item.store_id == undefined) ? localStorage.getItem("store_id") : item.store_id;
      this.add_to_cart.item_id = item.item_id;
      this.add_to_cart.item_variants_id = window.$("#itemvarintid" + item.item_id).val();
      this.add_to_cart.quantity = (this.item_qty == 0) ? window.$("#quantityid" + item.item_id).val() : this.item_qty;
      this.add_to_cart.cart_type = 1;
      this.remove_min_qty = false;

      if(this.getItemMinQty(item,index) != null){
        if(this.getItemMinQty(item,index) != 0 && this.getItemMinQty(item,index) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item,index) == false){
          this.is_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item,index) + ", Do you want to proceed?"
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        } else if(this.getItemMinQty(item,index) != 0 && this.getItemMinQty(item,index) > this.add_to_cart.quantity && this.add_to_cart.quantity != 0 && this.isItemAdded(item,index) == true) {
          this.is_min_qty = true;
          this.remove_min_qty = true;
          this.qty_alert_message = "The minimum quantity for item is " + this.getItemMinQty(item,index) + ", Do you wish to remove?"
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        }
      }

      if(this.getItemMaxQty(item,index) != null){
        if(this.getItemMaxQty(item,index) != 0 && this.getItemMaxQty(item,index) < this.add_to_cart.quantity){
          window.$("#quantityid" + item.item_id).val(parseInt(this.getItemMaxQty(item,index)));
          this.is_min_qty = false;
          this.qty_alert_message = "The maximum quantity for item is " + this.getItemMaxQty(item,index) + "."
          window.$('#QuantityAlertModal'+index).modal("show");
          return false;
        }
      }

      if(this.checkItemAddonChoice(item,index) != null && this.addon_choice_cart == false && this.add_to_cart.quantity != 0 && localStorage.getItem("user") != null){
        if(this.isItemAdded(item, index) == true && this.add_to_cart.add_new == 0){
          this.repeat_addon_choice = null;
          this.getRepeatAddonChoices(index, this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
        } else {
          this.getItemAddonChoices(item, index, item.item_name);
        }
        return false;
      } else {
        this.addon_choice_cart = false;
      }

      if(item.is_combo == 1 && this.combo_cart == false && this.add_to_cart.quantity != 0 && localStorage.getItem("user") != null){
        var is_repeat = false;
        if(this.isItemAdded(item, index) == true && this.add_to_cart.add_new == 0){
          this.repeat_combo = null;
          is_repeat = true;
          //this.getRepeatCombo(index, this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
        } else {
          is_repeat = false;
          //this.getItemCombo(item, index);
        }
        this.getItemCombo(item, index, is_repeat);
        return false;
      } else {
        this.combo_cart = false;
      }

      if(localStorage.getItem("user") == null){
        this.addToLocalCart(item, index);
      } else {
        await this.$store
          .dispatch("addtocartproduct", this.add_to_cart)
          .then(response => {
            this.cart_response = JSON.parse(JSON.stringify(response));
            if (this.cart_response != null) {
              if (this.cart_response.status == "0") {
                this.cart_message = this.cart_response.message;
                window.$('#ReplaceCartModal'+index).modal("show");
              } else {
                if (
                  this.storebestsellerlist[index].item_variants.find(
                    o => o.item_variants_id == this.item_variants[index].item_variants_id
                  ) != undefined
                ) {
                  let item_variant_index = item.item_variants.findIndex(
                      o => o.item_variants_id == this.item_variants[index].item_variants_id
                  );

                  if(this.add_to_cart.quantity != 0){
                    this.storebestsellerlist[index].item_variants[item_variant_index].is_added = 1;
                    this.storebestsellerlist[index].item_variants[item_variant_index].quantity = this.add_to_cart.quantity;
                  } else {
                    this.storebestsellerlist[index].item_variants[item_variant_index].is_added = 0;
                    this.storebestsellerlist[index].item_variants[item_variant_index].quantity = 1;
                  }

                }
                this.item_qty = 0;
                this.add_to_cart.add_new = 0;
                this.getCartCount();
                this.$toast.success(this.cart_response.message);
                if(this.add_to_cart.quantity != 0){
                  this.$router.push('/cart');
                } else {
                  window.location.reload();
                }
              }
            }
          });
      }
    },
    removeCart(item, index) {
      this.remove_cart.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.remove_cart.cart_type = 1;
      
      if(localStorage.getItem("user") == null){
        this.removeLocalCart();
        if(this.is_min_qty == true){
          this.addMinQty(item, index);
          this.is_min_qty = false;
        }
        this.addToLocalCart(item, index);
        window.$('#ReplaceCartModal'+index).modal("hide");
      } else {
        this.$store
          .dispatch("getemptycart", this.remove_cart)
          .then(response => {
            if (response.status == 1) {
              if(this.add_to_cart.item_id != ""){
                if(this.is_min_qty == true){
                  this.addMinQty(item, index);
                  this.is_min_qty = false;
                }
                this.addon_choice_cart = true;
                this.combo_cart = true;
                this.addToCart(item, index);
                window.$('#ReplaceCartModal'+index).modal("hide");
              }
            }
          });
      }
    },
    openLoginfrm(productid) {
      localStorage.setItem("SelectedProductBeforeLogin",productid);
      localStorage.setItem("LastUrlBeforeLogin",window.location.href);      
      window.$("#login-form").modal("show");
      window.$("#VerificationFields").removeClass("show");
      window.$("#SignupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#loginPopupFields").addClass("show");
    },
    productNotify(productid) {
      if(localStorage.getItem("user") == null){
        this.openLoginfrm(productid);
        return false;
      }
      this.product_notify.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.product_notify.item_id = productid;
      this.$store
        .dispatch("getcustnotifyme", this.product_notify)
        .then(response => {
          if (response.status == 1) {
            window.$("#btnnotifyme" + this.product_notify.item_id).removeClass("btn btn-primary");
            window.$("#btnnotifyme" + this.product_notify.item_id).addClass("btn notifymegrayitem");
            this.storebestsellerlist.find(
              o => o.item_id == this.product_notify.item_id
            ).notify_me = 1;
            this.$toast.success("You will be notified once the item is in stock");
          } else {
            this.$toast.error(response.message);
          }
        });
    },
    getActmenu(namekey, menuid, mainid) {
      if (namekey == "store_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.storetypearr.length == 0) {
            this.storetypearr = [];
          } else {
            this.storetypearr.splice(menuid, 1);
          }
          window.$("#new" + mainid + menuid).removeAttr("class");
        } else {
          this.storetypearr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "delivery_type") {
        let a = window.$("#new" + mainid + menuid).attr("class");
        if (a != undefined && a == "active") {
          if (this.deliveryarr.length == 0) {
            this.deliveryarr = [];
          } else {
            this.deliveryarr.splice(menuid, 1);
          }
          window.$("#new" + mainid + menuid).removeAttr("class");
        } else {
          this.deliveryarr.push(menuid);
          window.$("#new" + mainid + menuid).attr("class", "active");
        }
      } else if (namekey == "online_order") {
          let a = window.$("#new" + mainid + menuid).attr("class");
          if (a != undefined || a == "active") {
            if (this.onlineordrarr.length == 0) {
              this.onlineordrarr = [];
            } else {
              this.onlineordrarr.splice(menuid, 1);
            }

            window.$("#new" + mainid + menuid).removeAttr("class");
          } else {
            this.onlineordrarr.push(menuid);

            window.$("#new" + mainid + menuid).attr("class", "active");
          }
      }
    },
    checkItemAddonChoice(item, index){
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          return item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          ).is_choice_addon;
      } else {
        return null;
      }
    },
    getChoiceAddon(item, value) {
      return item.item_variants.find(i => i.is_choice_addon == value) ? value : "null";
    },
    getItemAddonChoices(item, index, item_name) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          );
          bus.$emit("ac_item", item);
          bus.$emit("ac_item_index", index);
          bus.$emit("ac_item_variant", selected_item_variant);
          bus.$emit("ac_item_name", item_name);
          window.$("#AvailableOptionallbestseller").modal("show");
      } else {
        return false;
      }
    },
    isItemExistsUsedAddonChoices(local_used_addon_choice, used_addon_choice){
      const i = local_used_addon_choice.findIndex(o => o.item_id == used_addon_choice.item_id && o.item_variants_id == used_addon_choice.item_variants_id);
      if (i > -1) {
        local_used_addon_choice[i] = used_addon_choice;
      } else { 
        local_used_addon_choice.push(used_addon_choice);
      }
      return local_used_addon_choice;
    },
    addToCartAddonChoices(data){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 1;
      this.add_to_cart.choices = JSON.stringify(data.choices);
      this.add_to_cart.addons = JSON.stringify(data.addons);

      // Used Addon Choice Local Storage
      this.used_addon_choice.item_id = data.item.item_id;
      this.used_addon_choice.item_name = data.item.item_name;
      this.used_addon_choice.brand_name = data.item.brand_name;
      this.used_addon_choice.item_variants_id = data.item_variant.item_variants_id;
      this.used_addon_choice.item_price = (parseFloat(data.item_variant.discounted_price) + parseFloat(data.addon_choice_price));
      this.used_addon_choice.choice_details = data.choices;
      this.used_addon_choice.addon_details = data.addons;

      var local_used_addon_choice = JSON.parse(localStorage.getItem("used_addon_choice")) || [];
      if(localStorage.getItem("used_addon_choice") != null) {
        var new_used_addon_choice = this.isItemExistsUsedAddonChoices(local_used_addon_choice, this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(new_used_addon_choice));
      } else {
        local_used_addon_choice.push(this.used_addon_choice);
        localStorage.setItem('used_addon_choice', JSON.stringify(local_used_addon_choice));
      }
      this.addToCart(data.item, data.index);

    },
    repeatItemAddonChoices(item, index){
      this.addon_choice_cart = true;
      this.add_to_cart.add_new = 0;
      this.addToCart(item, index);
      window.$('#RepeatAlertModal'+index).modal("hide");
    },
    addNewItemAddonChoices(item, index){
      this.addon_choice_cart = false;
      this.add_to_cart.add_new = 1;
      this.addToCart(item, index);
      window.$('#RepeatAlertModal'+index).modal("hide");
    },
    getRepeatAddonChoices(index, store_id, item_variants_id){
      this.used_choices_addons.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_choices_addons.store_id = store_id;
      this.used_choices_addons.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_choices_addons)
        .then(response => {
          if (response.status == 1) {
            this.repeat_addon_choice = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatAlertModal'+index).modal("show");
          } else {
            this.repeat_addon_choice = null;
          }
        });
    },
    getItemCombo(item, index, is_repeat) {
      if (
        item.item_variants.find(
          o => o.item_variants_id == this.item_variants[index].item_variants_id
        ) != undefined
      ) {
          var selected_item_variant = item.item_variants.find(
            o => o.item_variants_id == this.item_variants[index].item_variants_id
          );
          bus.$emit("comb_item", item);
          bus.$emit("comb_item_index", index);
          bus.$emit("comb_item_variant", selected_item_variant);
          bus.$emit("comb_item_name", item.item_name);
          this.checkComboItem(item, index, selected_item_variant, is_repeat);
      } else {
        return false;
      }
    },
    checkComboItem(item, index, selected_item_variant, is_repeat) {
       this.combo_item_data.item_id = item.item_id;
       this.combo_item_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
       this.$store
         .dispatch("getproductdetail", this.combo_item_data)
         .then(response => {
           this.combo_loading = false;
           var result = JSON.parse(JSON.stringify(response));
           if(result.status == 1 && result.data.combo_items != null){
             this.combo_item_details = result.data.combo_items;
             var vm = this;
             this.selectedComboItems = [];
             var has_addon_choices = false;
             this.combo_item_details.forEach(function(combo_items) {
              var filter_combo_items = combo_items.combo_variants.filter(sub => sub.addons != null || sub.choices != null);
              if(!has_addon_choices){
                has_addon_choices = (filter_combo_items.length > 0) ? true : false;
              }
              
              combo_items.combo_variants.forEach(function(citem) {
                var obj = {};
                obj['combo_item_id'] = combo_items.combo_item_id;
                obj['item_name'] = citem.item_name;
                obj['combo_item_detail_id'] = citem.combo_item_detail_id;
                obj['quantity'] = 1;
                vm.selectedComboItems.push(obj);
              });
             });
             result.data.combo_items.forEach(comboVariantObj => {
               if(comboVariantObj.combo_variants.length > 1)
               {
                 has_addon_choices = true;
               }
             })
             if(!has_addon_choices) {
               var selected_data = {};
               selected_data['item'] = item;
               selected_data['index'] = index;
               selected_data['item_variant'] = selected_item_variant;
               selected_data['combo_items'] = this.selectedComboItems;
               this.addToCartCombo(selected_data);
             } else {
                if(is_repeat){
                  this.getRepeatCombo(index, this.add_to_cart.store_id, this.add_to_cart.item_variants_id);
                } else {
                  window.$("#comboOfferallbestseller").modal("show");
                }
             }
           }
         });
    },
    isItemExistsUsedCombo(local_used_combo, used_combo){
      const i = local_used_combo.findIndex(o => o.item_id == used_combo.item_id && o.item_variants_id == used_combo.item_variants_id);
      if (i > -1) {
        local_used_combo[i] = used_combo;
      } else { 
        local_used_combo.push(used_combo);
      }
      return local_used_combo;
    },
    addToCartCombo(data){
      this.combo_cart = true;
      this.add_to_cart.add_new = 1;
      this.add_to_cart.combo_items = JSON.stringify(data.combo_items);
      
      // Used Addon Choice Local Storage
      this.used_combo.item_id = data.item.item_id;
      this.used_combo.item_name = data.item.item_name;
      this.used_combo.brand_name = data.item.brand_name;
      this.used_combo.item_variants_id = data.item_variant.item_variants_id;
      this.used_combo.item_price = (parseFloat(data.item_variant.discounted_price));
      this.used_combo.combo_items = data.combo_items;

      var local_used_combo = JSON.parse(localStorage.getItem("used_combo")) || [];
      if(localStorage.getItem("used_combo") != null) {
        var new_used_combo = this.isItemExistsUsedCombo(local_used_combo, this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(new_used_combo));
      } else {
        local_used_combo.push(this.used_combo);
        localStorage.setItem('used_combo', JSON.stringify(local_used_combo));
      }
      this.addToCart(data.item, data.index);

    },
    repeatItemCombo(item, index){
      this.combo_cart = true;
      this.add_to_cart.add_new = 0;
      this.addToCart(item, index);
      window.$('#RepeatComboAlertModal'+index).modal("hide");
    },
    addNewItemCombo(item, index){
      this.combo_cart = false;
      this.add_to_cart.add_new = 1;
      this.addToCart(item, index);
      window.$('#RepeatComboAlertModal'+index).modal("hide");
    },
    getRepeatCombo(index, store_id, item_variants_id){
      this.used_combo_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.used_combo_data.store_id = store_id;
      this.used_combo_data.item_variants_id = item_variants_id;
      this.$store
        .dispatch("usedchoicesaddons", this.used_combo_data)
        .then(response => {
          if (response.status == 1) {
            this.repeat_combo = JSON.parse(JSON.stringify(response.data));
            window.$('#RepeatComboAlertModal'+index).modal("show");
          } else {
            this.repeat_combo = null;
          }
        });
    },
    closeAddNewCombo(data){
      if(data){
        this.add_to_cart.add_new = 0;
      }
    },
    filterComboAddons(addons){
      var tmp_addons = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(addon_price => {
          if(addon_price.is_added == 1){
            var obj = {};
            obj['variant_addon_name'] = addon.variant_addon_name;
            obj['variant_sub_addon_name'] = addon_price.variant_sub_addon_name;
            tmp_addons.push(obj);
          }
        });
      });
     return tmp_addons;
    },
    filterComboChoices(choices){
      var tmp_choices = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(choice_price => {
          if(choice_price.is_added == 1){
            var obj = {};
            obj['variant_choice_name'] = choice.variant_choice_name;
            obj['variant_sub_choice_name'] = choice_price.variant_sub_choice_name;
            tmp_choices.push(obj);
          }
        });
      });
     return tmp_choices;
    },
   
  }
};
</script>